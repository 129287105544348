<template>
  <div class="course-content">
    <!-- 头部 -->
    <div class="header">
      <div class="header-container" @click="goBack()">
        <div class="back">
          <img src="../../assets/back-left.png" alt="" style="width: 30px; height: 30px" />
        </div>
        <div class="header-name">
          {{ courseContentInfo.name }}
        </div>
      </div>
    </div>
    <!-- 播放视频区域 -->
    <div class="main">
      <div class="left">
        <div id="player-con" class="player"></div>
      </div>
      <div class="right">
        <div class="chapter-list">
          <div class="chapter-item" v-for="(item, index) in courseChapterList" :key="index">
            <Chapter :chapterInfo="item" :marginPx="0" :classId="Number(classId)" :courseId="Number(id)"></Chapter>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCourseContentDetail,
  getCourseInfo,
  getVideoPlayAuth,
  getLivePlay,
  addCourseRecords
} from "@/api/course";
import Chapter from "@/components/chapter";
var interTime = undefined;
import chatURL from "@/utils/api_host.js";
import { Message } from "element-ui";
var courseContentId = 0;
var _this;
// window.addEventListener('beforeunload',_this.beforeUpdate)
window.onbeforeunload = function () {
  _this.addCourseRecords(0)
}
export default {
  data() {
    return {
      id: 0,
      courseContentInfo: {},
      courseChapterList: [],
      classId: 0,
      socket: null,
    };
  },
  components: {
    Chapter,
  },
  mounted() {
    _this = this;
    this.id = this.$route.query.id;
    this.classId = this.$route.query.classId;
    this.getCourseInfo(this.classId);
    this.getCourseContentDetail(this.id);
    if (typeof WebSocket === "undefined") {
      // console.log("您的浏览器不支持socket");
    } else {
      this.initWebSoket();
    }
  },
  beforeRouteUpdate(a, b, c) {
    _this.addCourseRecords(0)
    c()
  },
  beforeDestroy() {
    console.log('执行保存');
    if (courseContentId != 0 && _this.isfinish) {
      _this.addCourseRecords(0);
    }
    if (this.player != null) {
      this.player.dispose();
    }
    this.closeSoket();
  },
  methods: {
    initWebSoket() {
      // token / 用户id
      var ws =
        chatURL.demand +
        localStorage.getItem("web-token") +
        "/" +
        localStorage.getItem("userId");
      // 实例化socket
      this.socket = new WebSocket(ws);
      // 监听socket连接
      this.socket.onopen = function () {
        var info = JSON.stringify({ type: 0 });
        interTime = setInterval(() => {
          this.send(info);
        }, 5000);
      };
      // 监听socket错误信息
      this.socket.onerror = function (e) {
        // console.log("发生错误" + e.data);
      };
      // 监听socket消息
      this.socket.onmessage = async function (e) {
        var data = JSON.parse(e.data);
        if (data.type == 4) {
          // 账号被他人登录时返回的消息类型
          _this.loginOut();
        }
      };
    },
    send(info) {
      this.socket.send(info);
    },
    loginOut() {
      setTimeout(function () {
        Message.error("该账号已在其它地方登录");
        this.socket.close();
        this.$router.push("/login");
      }, 1500);
    },
    closeSoket() {
      if (this.socket) {
        this.socket.close();
        clearInterval(interTime);
      }
    },
    //获取视频
    getVideoPlayAuth(id, progress) {
      console.log(id)
      courseContentId = id;
      getVideoPlayAuth(id).then((res) => {
        if (res != undefined) {
          if (this.player != null) {
            // 先销毁再播放，否则视频会重叠
            this.player.dispose();
          }
          // 绑定id，播放视频
          this.player = new Aliplayer(
            {
              id: "player-con",
              vid: res.data.vid,
              playauth: res.data.playAuth,
              // "source": "//player.alicdn.com/video/aliyunmedia.mp4",
              qualitySort: "asc",
              width: "100%",
              height: "100%",
              format: "m3u8",
              mediaType: "video",
              definition: "FD,LD", // 显示视频清晰度(流畅、标清)
              defaultDefinition: "FD", // 默认视频清晰度
              disableSeek: false, // 禁用进度条仅Flash支持。
              autoplay: true,
              isLive: false, // 是否直播,直播状态开启才能禁止进度条
              rePlay: false,
              playsinline: true,
              preload: true,
              controlBarVisibility: "hover",
              useH5Prism: true,
              cover: this.courseContentInfo.logo,
            },
            function (player) {
              // 相关api查看：https://help.aliyun.com/document_detail/125572.htm?spm=a2c4g.11186623.0.0.797710e4KeHz9o#task-1997027
              // 监听播放事件
              var seeked = false;
              player.on("canplaythrough", function (e) {
                if (!seeked) {
                  seeked = true;
                  // 设置播放器进度
                  player.seek(progress == undefined ? 0 : progress);
                }
              });
              player.on("playing", function () {
                // console.log("正在播放");
                _this.isfinish = true;
              });
              player.on("ended", function () {
                // console.log("播放完毕");
                _this.isfinish = false;
                _this.addCourseRecords(1);
              });
            }
          );
        }
      });
    },
    // 添加视频观看记录 0 播放未完成 1 播放完成
    addCourseRecords(isfinish) {
      console.log(_this);
      console.log(_this.player)
      this.playVideo = false;
      addCourseRecords({
        classId: this.classId,
        courseContentId: courseContentId,
        finish: isfinish,
        progress: _this.player.getCurrentTime(),
      }).then((res) => {

      });
    },
    getCourseContentDetail(id) {
      getCourseContentDetail({ id }).then((res) => {
        this.courseContentInfo = res.data;
        this.getVideoPlayAuth(this.id);
      });
    },
    getCourseInfo(id) {
      getCourseInfo(id).then((res) => {
        if (res.code == 200) {
          this.courseChapterList = res.data;
        }
      });
    },
    //返回上一页
    goBack() {
      this.$router.push({
        path: localStorage.getItem("page"),
        query: {
          id: localStorage.getItem("id"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  background-color: black;
}

.course-content {
  min-height: 100vh;
  background-color: #f4f5f8;

  .header {
    width: 100%;
    height: 100px;
    background-color: #fff;

    .header-container {
      display: flex;
      align-content: center;
      margin: 0 110px;
      height: 100%;
      font-size: 20px;
      font-weight: 400;
      line-height: 100px;
      cursor: pointer;

      .back {
        margin-right: 20px;

        img {
          position: relative;
          transform: translate(0, 20%);
        }
      }
    }
  }
}

.main {
  display: flex;
  margin: 20px;

  .left {
    width: calc(100vw - 25vw - 10px);
  }

  .right {
    margin-left: 10px;
    background-color: #fff;
    padding: 20px;
    cursor: pointer;

    .chapter-list {
      overflow-y: scroll;
      height: calc(80vh);
      width: 25vw;
    }
  }
}
</style>